<template>
    <span class="chapter-field ml10">
        <el-button size="mini" type="primary" @click="editHandle(scope)">修改排序</el-button>
        <el-dialog title="更改排序" :visible.sync="dialogFormVisible" center width="600px">
            <el-form inline>
                <el-col :span="18" :offset="6">
                    <span class="span_sty">将<span class="span_cont">{{this.scope.correlation_name}}</span>调整到</span>
                    <el-form-item>
                        <el-select v-model="checkRelatedVideo" placeholder="请选择活动区域">
                            <el-option
                            v-for="item in relatedVideoList"
                            :key="item.correlation_id"
                            :label="item.correlation_name"
                            :value="item.correlation_id"></el-option>
                        </el-select>
                        <span class="span_sty">的后面</span>
                    </el-form-item>
                </el-col>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="editSort">确 定</el-button>
            </div>
        </el-dialog>
    </span>
</template>

<script>
/*
 * @Author: daipeng
 * @Date: 2018-09-21 13:33:56
 * @LastEditors: OBKoro1
 * @LastEditTime: 2018-10-18 09:13:59
 * @Description: 章节列表-行内编辑
 * @Company: 成都二次元动漫
 */

export default {
    props: {
        scope: Object
    },
    data() {
        return {
            // sort: '',
            cloneRowData: null,
            dialogFormVisible: false,
            relatedVideoList: [],
            checkRelatedVideo: '',
            animation_id: '',
            correlation_id: ''
        };
    },
    methods: {
        // 点击编辑
        editHandle() {
            this.dialogFormVisible = true;
            this.cloneRowData = this.$utils.cloneDeep(this.scope);
            const { animation_id, correlation_id } = this.scope;
            this.animation_id = animation_id;
            this.correlation_id = correlation_id;
            this.getRelatedVideoList();
        },

        // 确认修改
        editSort() {
            this.$api('sortRelatedVideo', { animation_id: this.animation_id, curr_correlation_id: this.correlation_id, target_correlation_id: this.checkRelatedVideo }).then(res => {
                this.$message.success(`修改成功！`);
                this.dialogFormVisible = false;
                this.$eventHub.$emit('EV_REFRESH_RELATED_VIDEO_LIST');
            });
        },

        // 获取相关视频列表
        getRelatedVideoList() {
            this.$api('getAnComicRelatedVideoList', { animation_id: this.animation_id, page_size: 9999, page_index: 1 }).then(res => {
                res.data.rows.unshift({
                    correlation_id: 0,
                    correlation_name: '第一位'
                });
                this.relatedVideoList = res.data.rows;
                this.relatedVideoList.some(item => {
                    if (item.correlation_id === this.correlation_id) {
                        this.checkRelatedVideo = item.correlation_id;
                    }
                    return item.correlation_id === this.correlation_id;
                });
            });
        }
    }
};
</script>

<style lang="scss" scoped>
    .el-icon-edit{
        cursor: pointer;
    }
    .span_sty{
        display: inline-block;
        font-size: 16px;
        margin-bottom: 15px;
        .span_cont{
            display: inline-block;
            color: #409EFF;
            margin: 0 10px;
            font-size: 16px;
        }
    }
</style>
