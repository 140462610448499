<template>
    <div class="my-comic page-container flex-column">
        <div class="my-comic-operation">
            <div class="flex-row-between">
                <div class="flex-row-center">
                    <el-button type="primary" size="mini" @click="addVideoHandle">新增相关视频</el-button>
                    <el-button size="mini" @click="displayComicHandle(1)">上架</el-button>
                    <el-button size="mini" @click="displayComicHandle(0)">下架</el-button>
                </div>
            </div>
        </div>
        <div class="my-comic-selectbox">
            <i class="el-icon-info color-green mr10"></i><span class="mr10">共 {{pagination.total}} 项</span><span class="mr10">已选择 <span class="color-green">{{selectComicList.length}}</span> 项</span>
            <el-button type="text" :disabled="selectComicList.length === tableList.length" @click="selectAllHandle">全选</el-button>
            <el-button type="text" :disabled="!selectComicList.length" @click="removeSelectHandle">清空</el-button>
        </div>
        <div v-show="!isTable" class="my-comic-table" ref="cardTable" v-loading="isloading" element-loading-text="加载中..." element-loading-spinner="el-icon-loading" v-empty="noData">
            <template v-if="tableList.length">
                <relatedVideoCard ref="card" v-for="(card, index) in tableList" :key="card.correlation_id" :index="index" :showSelect="true" :list.sync="tableList" :selectComicList.sync="selectComicList" :comicData="card"></relatedVideoCard>
            </template>
        </div>
        <paginationPlus :currentPage.sync="pagination.pageIndex" :pageSizes="[8, 20, 30, 40]" :pageSize.sync="pagination.pageSize" :total="pagination.total" @callback="pageChangeHandle"></paginationPlus>
    </div>
</template>
<script>
/*
 * @Author: daipeng
 * @Date: 2018-09-12 20:00:22
 * @LastEditors: OBKoro1
 * @LastEditTime: 2018-10-12 17:33:59
 * @Description: 管理动态漫-相关视频
 * @Company: 成都二次元动漫
 */
import { searchMixin, paginationMixin } from '@/mixins';
import relatedVideoCard from '@/components/comic/relatedVideoCard.vue';
import auditComicDialog from '@/views/main/adminCenter/auditComic/auditComicDialog';
import comicPublish from '@/components/comic/comicPublish';

export default {
    mixins: [searchMixin, paginationMixin],
    components: { auditComicDialog, comicPublish, relatedVideoCard },
    data() {
        return {
            platformList: null, // 平台列表
            isloading: false,
            noData: true,
            pagination: {
                page_size: 8
            },
            searchData: {
                animation_id: ''
            },
            tableList: [],
            isTable: false,
            currentPublishComicData: null, // 当前点击发布的章节信息
            selectComicList: [] // 选择的相关视频
        };
    },
    created() {
        this.$eventHub.$on('EV_REFRESH_RELATED_VIDEO_LIST', () => {
            this.getTableList();
        });
    },
    mounted() {
        const { animation_id } = this.$route.query;
        this.searchData.animation_id = animation_id;
        this.getTableList();
    },
    methods: {
        // 选择所有相关视频
        selectAllHandle() {
            this.selectComicList = this.tableList;
        },
        // 清空所选相关视频
        removeSelectHandle() {
            this.selectComicList = [];
        },
        // 相关视频上/下架
        displayComicHandle(show_status) {
            if (!this.selectComicList.length) return this.$message.info('请先选择相关视频');
            const msg = show_status === 0 ? '下架' : '上架';
            this.$utils.confirm('', `确定${msg}选中相关视频吗？`, `${msg}中...`, (action, instance, done) => {
                return this.$api('displayAnComicVideo', { show_status, correlation_ids: this.selectComicList.map(comic => comic.correlation_id) }).then(res => {
                    this.selectComicList = [];
                    this.getTableList();
                });
            });
        },
        // 新增相关视频
        addVideoHandle() {
            this.$router.push({ name: 'addRelatedVideo', query: { editType: 'add' } });
        },
        // 查询列表
        getTableList() {
            this.isloading = true;
            let pagination = {};
            for (let [key, value] of Object.entries(this.pagination)) {
                if (key === 'total') {
                    continue;
                }
                pagination[this.$utils.snakeCase(key)] = value;
            }

            return this.$api('getAnComicRelatedVideoList', { ...this.searchData, ...pagination }).then(res => {
                this.$nextTick(() => {
                    this.$refs.cardTable.scrollTop && (this.$refs.cardTable.scrollTop = 0);
                    const { rows, page_index, page_size, total } = res.data;
                    this.noData = (rows.length !== 0);
                    this.tableList = rows;
                    this.pagination = {
                        ...this.pagination,
                        pageIndex: page_index,
                        pageSize: page_size,
                        total
                    };
                    this.isloading = false;
                });
            });
        },
        // 分页
        pageChangeHandle({ page, size }) {
            this.getTableList();
        }
    }
};
</script>

<style lang="scss">
    .my-comic{
        &.page-container{
            padding: 20px 0 0;
        }
        .search-input{
            width: 200px;
        }
        &-operation{
            padding: 0 10px;
            flex-basis: 54px;
            .tab-icon{
                margin-left: 5px;
            }
        }
        &-table{
            display: flex;
            flex-grow: 1;
            width: 100%;
            flex-wrap: wrap;
            justify-content: flex-start;
            box-sizing: border-box;
            padding: 10px 0  10px 20px;
            position: relative;
            overflow-y: auto;
            &-list{
                flex-grow: 1;
                margin: 10px 20px;
                .el-table__body-wrapper{
                    height: calc(100% - 40px);
                    position: relative;
                    overflow: auto;
                }
            }
        }
        &-publish{
            padding: 20px;
            .el-checkbox-group{
                flex-flow: row wrap;
                .el-checkbox{
                    min-width: 100px;
                }
            }
            .el-checkbox{
                margin: 0 30px 10px 0;
            }
        }
        &-selectbox{
            flex-basis: 34px;
            padding: 0 10px;
            margin: 15px 20px 10px;
            border-radius: 4px;
            background-color: rgba(230, 247, 255, 1);
            border: 1px solid rgba(186, 231, 255, 1);
        }
    }
    .el-message-box__title{
        span{
            font-size: 16px;
        }
    }
    label.platform-checkbox{
        margin-left: 0;
        margin-right: 30px;
    }
</style>
